<template>
  <div>
    <b-form>
      <b-form-group id="input-group-2" label="Player Name:" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="name"
          required
          placeholder="Player Name:"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Color:" label-for="input-3">
        <b-form-select
          id="input-3"
          v-model="color"
          :options="colors"
          required
        ></b-form-select>
      </b-form-group>
      <b-button type="submit" variant="primary" @click="addPlayer"> Add Player</b-button>
    </b-form>

  </div>
</template>

<script>
import { mapState  } from 'vuex'

export default {
    data() {
      return {
        name: '',
        color: '',
        colors: [{ text: 'Black', value: 'black' }, 
                  { text: 'Blue', value: 'blue' },
                  { text: 'Brown', value: 'brown' },
                  { text: 'Cyan', value: 'cyan' },
                  { text: 'Green', value: 'green' },
                  { text: 'Lime', value: 'lime' },
                  { text: 'Orange', value: 'orange' },
                  { text: 'Pink', value: 'pink' },
                  { text: 'Purple', value: 'purple' },
                  { text: 'Red', value: 'red' },
                  { text: 'White', value: 'white' },
                  { text: 'Yellow', value: 'yellow' }]
      }
    },
    methods: {
       addPlayer() {
          this.$store.dispatch("ADD_PLAYER", [ this.name, this.color]);
          this.name = ''
          this.color = ''
       }
    },
      computed: {
        ...mapState({
          players: state => state.player.players
      })
    }
  }

</script>

<style scoped>
  .title {
    color: #888;
    font-size: 18px;
    font-weight: initial;
    letter-spacing: .25px;
    margin-top: 10px;
  }
  .doc button {
    font-size: .8em;
    cursor: pointer;
    outline: none;
    padding: 0.75em 2em;
    border-radius: 2em;
    display: inline-block;
    color: #fff;
    background-color: #939ba2;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 1px solid #939ba2;
  }

  .doc button.alt {
    color: #939ba2;
    background-color: transparent;
  }
</style>