import Vue from 'vue'
import axios from 'axios'
import VueAnalytics from 'vue-analytics'

import App from './App'
import router from './router'
import store from './store'



import {BootstrapVue , BootstrapVueIcons} from 'bootstrap-vue'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueAnalytics, {
  id: 'UA-180093841-1',
  router
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.http = Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.config.silent = true

/* eslint-disable no-new */
new Vue({
  components: { App },
  router,
  store,
  render: h => h(App),
  template: '<App/>',
}).$mount('#app')
