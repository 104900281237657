import Vue from 'vue'
import Router from 'vue-router'


import GamePage from '@/components/GamePage'
import NotesPage from '@/components/NotesPage'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'game-page',
      component: GamePage
    },
    {
      path: '/notespage',
      name: 'notes-page',
      component: NotesPage
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
