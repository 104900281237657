<template> 
<button  class='accused-button dd' v-b-modal="'modal-prevent-acc-' + player.id">
     Accused by   <b-icon icon="people-fill">  </b-icon>
  <div>
    <b-modal v-bind:id="'modal-prevent-acc-' + player.id"  ref="modal" hide-footer v-bind:title="'Who Accused ' +  player.name + '?'">
        <div class="d-block text-center">
        </div>     
          <b-list-group style="max-width: 300px;">
            <div v-bind:key="p.id" v-for="p in allPlayers">
              <b-list-group-item v-if="p.id != player.id" class="d-flex align-items-center" @click="addAccusal(p.id)">
                <div>
                  <b-avatar :src="require(`../../assets/crew/${encodeURIComponent(p.color)}.png`)" alt="icon"  icon="people-fill" class="mr-3"></b-avatar>
                  <span class="mr-auto">{{p.name}}</span>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
    </b-modal>
  </div>
</button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    props: {
      player: {},
    },
    computed: {
      ...mapGetters (['allPlayers']),
      ...mapState({
        players: state => state.player.players
    }),
    },
    methods: {
       addAccusal(accuser_id) {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-acc-' + this.player.id)
            
            this.$store.dispatch("ACCUSE", [ accuser_id, this.player.id]);
            // console.log("done")
          })
       }
    },

}
</script>

<style>

</style>