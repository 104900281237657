<template>
  <div id="wrapper">
 <main>
      <div class="">
        <div class="doc">
          <h1> Welcome To AmongUs.Win </h1>
          <h6>
            A Stupid Little App by <a href="https://twitter.com/kevanpeters">@kevanpeters</a>. I am not responsible for the friendships you might destroy using this;)
          </h6>
          <h6>
             <a href="https://youtu.be/l8ZstNHrzsU">I also created a video, feel free to check it out if you would like to support the work!</a>
          </h6>    
          <br>
          <div class="title">Pick your Team</div>
          <p>
               Add the name and color for the players you are playing with
          </p>  
            <UserSelection></UserSelection>
            <br>
          <UserList></UserList>
            <br>
          <button @click="startNewGame(); $router.push('notespage');">Start Game</button>
          <button  @click="clearPlayers">Clear Players</button>
            <br>
            <br>
            <br>
            <br>
            <br><br>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
    import UserSelection from './GamePage/UserSelection'
    import UserList from './GamePage/UserList'
    // import { mapState  } from 'vuex'

  export default {
    components: { UserSelection, UserList },
    name: 'game-page',
    methods: {
      clearPlayers() {
          this.$store.dispatch("CLEAR_PLAYERS");
       },
       startNewGame() {
         this.$store.dispatch("NEW_GAME");
       }
    }
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body { font-family: 'Source Sans Pro', sans-serif; }


  #logo {
    height: auto;
    margin-bottom: 20px;
    width: 420px;
  }

  main {
    display: flex;
    justify-content: space-between;
  }

  main > div { flex-basis: 50%; }

  .left-side {
    display: flex;
    flex-direction: column;
  }

  .welcome {
    color: #555;
    font-size: 23px;
    margin-bottom: 10px;
  }

  .title {
    color: #2c3e50;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .title.alt {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .doc p {
    color: black;
    margin-bottom: 10px;
  }
  .doc p {
    color: black;
    margin-bottom: 10px;
  }

    .doc button {
    font-size: .8em;
    cursor: pointer;
    outline: none;
    padding: 0.75em 2em;
    border-radius: 2em;
    display: inline-block;
    color: #fff;
    background-color: #939ba2;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 1px solid #939ba2;
    margin: 2px;
  }

  .doc button.alt {
    color: #939ba2;
    background-color: transparent;
  }

</style>
