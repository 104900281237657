<template> 
<button class='action-button' v-b-modal="'modal-prevent-' + player.id + '-dead'">
  Player Dead <b-icon icon="trash"/>
  <div>
    <b-modal v-bind:id="'modal-prevent-' + player.id + '-dead'"  ref="modal" hide-footer v-bind:title="player.name + ' is dead!'">
      <div class="d-block text-center">
          <h3> Who Found The body? </h3>
       </div>     
    <b-list-group style="max-width: 300px;">
      <div v-bind:key="p.id" v-for="p in allPlayers">
        <b-list-group-item v-if="p.id != player.id" class="d-flex align-items-center"  @click="addDead(p.id)">
          <b-avatar :src="require(`../../assets/crew/${encodeURIComponent(p.color)}.png`)" alt="icon"  icon="people-fill" class="mr-3"></b-avatar>
          <span class="mr-auto">{{p.name}}</span>
        </b-list-group-item>
      </div>
        <b-list-group-item class="d-flex align-items-center" @click="addDead('noOne')">
          <b-avatar icon="question-octagon" alt="icon" class="mr-3"></b-avatar>
          <span class="mr-auto">No one Found the Body!</span>
        </b-list-group-item>
    </b-list-group>
    </b-modal>
  </div>
</button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'


export default {
    props: {
      player: {},
    },
    computed: {
      ...mapGetters (['allPlayers']),
      ...mapState({
        players: state => state.player.players
    }),
    },
    methods: {
       addDead(found_body_id) {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-' + this.player.id + '-dead')
            this.$store.dispatch("PLAYER_DEAD", [this.player.id, found_body_id]);
            // console.log("done")
          })
       }
    }
}
</script>

<style>

</style>