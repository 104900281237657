<template>

<div>         
  <b-overlay :show="!player.alive"  opacity="0.45">
      <b-list-group-item class="d-flex">
        <b-avatar badge-variant="warning" badge-left badge-top size="80px" :src="require(`../../assets/crew/${this.player.icon}.png`)" icon="people-fill" class="mr-3">
          <template  v-if="player.noteSus.length > 0 || player.accusedBy.length > 0" v-slot:badge> {{player.noteSus.length + player.accusedBy.length}}</template>
        </b-avatar>
           <div  class="mr-auto"> 
              <b-row>
                <b-col>
          <span> <h3> {{player.name}} </h3></span>
                </b-col>
            </b-row>
                <b-row>
                <b-col   >
        <PlayerDeadPicker v-bind:player="player"/>
                </b-col>
            </b-row>
                            <b-row>
                <b-col  >
        <PlayerVotedOff v-bind:player="player"/>
                      </b-col>
            </b-row>
        </div>
        
        <span class="mr-auto"  v-if="player.foundTheirBody">
           <h5> Found Body</h5>
           <b-avatar-group size="50px" rounded="lg">
           <div>
              <b-avatar :src="require(`../../assets/crew/${player.foundTheirBody}.png`)"/>
           </div>
            </b-avatar-group>
        </span>
        <span class="mr-auto"  v-if="player.votedOffBy != 0">
          <h5> Voted off by! </h5>
           <b-avatar-group size="50px" rounded="lg">
           <div v-for="value3 in player.votedOffBy" :key="value3">
              <b-avatar :src="require(`../../assets/crew/${value3}.png`)"/>
           </div>
            </b-avatar-group>
        </span>
        <span class="mr-auto" v-if="player.accusedWho != 0">
           <h5> Accused </h5>
           <b-avatar-group size="50px" rounded="lg" >
           <div v-for="value in player.accusedWho" :key="value">
              <b-avatar :src="require(`../../assets/crew/${value}.png`)"/>
           </div>
            </b-avatar-group>
        </span>
        <span class="mr-auto" v-if="player.accusedBy != 0">
          <h5> Accused By </h5>
          <b-avatar-group size="50px" rounded="lg">
           <div v-for="value2 in player.accusedBy" :key="value2">
              <b-avatar :src="require(`../../assets/crew/${value2}.png`)"/>
           </div>
          </b-avatar-group>
        </span>

      <span class="mr-auto" v-if="player.noteSus.length > 0">
        <h5>Suspicions Notes</h5>
        <li class="text-warning list-note" v-for="value in player.noteSus" :key="value">
            {{ value }}
        </li>
      </span>

    <span class="mr-auto" v-if="player.noteGood.length > 0">
        <h5>Validating Notes</h5>
        <li class="text-success list-note" v-for="value in player.noteGood" :key="value">{{ value }}</li>
      </span>
        <!-- <b-badge v-if="player.noteSus.length > 0" variant="warning">{{player.noteSus.length}}</b-badge>
        <b-badge v-if="player.noteGood.length > 0" variant="success">{{player.noteGood.length}}</b-badge> -->
        <div>

          
            <!-- <b-dropdown id="dropdown-1" text="Actions" class="mr-auto">

                    <PlayerDeadPicker v-bind:player="player"/>

                    <PlayerVotedOff v-bind:player="player"/>

                <b-dropdown-divider></b-dropdown-divider>

                <PlayerAccused v-bind:player="player"/>

                
            </b-dropdown> -->

            <b-container class="mr-auto"> 
              <b-row>
                <b-col cols="auto"  >
                  <PlayerAccused v-bind:player="player"/>
                </b-col>
            </b-row>
              <b-row>
                <b-col cols="auto">
                  <SusNoteInput v-bind:player="player" v-bind:text="'Suspicions'" v-bind:color="'warning'"/>
                  </b-col>
              </b-row>
            <b-row>
              <b-col cols="auto">
                <GoodNoteInput v-bind:player="player" v-bind:text="'Validation'" v-bind:color="'success'"/>
    
            </b-col>
              </b-row>
            </b-container>
        </div>    

      </b-list-group-item>
        <template v-slot:overlay>
          <div class="text-center">
            <!-- <b-avatar :src="require(`../../assets/crew/${player.icon}.png`)"/>
            <p> is dead </p> -->
            <b-button
              ref="cancel"
              variant="outline-danger"
              size="sm"
              aria-describedby="cancel-label"
              @click="revertDeath()"
            >
              Revert
            </b-button>
          </div>
      </template>
    </b-overlay>
<br>
</div>
</template>

<script>
  import SusNoteInput from './SusNoteInput'
  import GoodNoteInput from './GoodNoteInput'
  import PlayerAccused from './PlayerAccused'
  import PlayerDeadPicker from './PlayerDeadPicker'
  import PlayerVotedOff from './PlayerVotedOff'
  import { mapGetters} from 'vuex'

  export default {
    components: { SusNoteInput, GoodNoteInput , PlayerAccused, PlayerDeadPicker, PlayerVotedOff},
     props: ["player"],
    computed: mapGetters(['getIconbyid']),
    data () {
      return {
          imageSource: require('../../assets/crew/' + this.color + '.png'), //this.player.color
      }
    },
    methods: {
       revertDeath() {
          this.$nextTick(() => {
          this.$store.dispatch("REVERT_DEAD_VOTED", this.player.id);
          console.log
          })
       }
    },
  }
</script>

<style scoped>
  .title {
    color: #888;
    font-size: 18px;
    font-weight: initial;
    letter-spacing: .25px;
    margin-top: 10px;
  }

  .action-button {
    font-size: .85em;
    cursor: pointer;
    outline: none;
    padding: 0.25em 1em;
    border-radius: .5em;
    display: inline-block;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 2px solid #939ba2;
    margin: 3px;
  }


    .accused-button {
    cursor: pointer;
    outline: none;
    padding: .75em 1em;
    border-radius: .4em;
    color: #fff;
    background-color: #ff6961 ;
    transition: all 0.15s ease;
    border: 0px solid #ffff;
  }


  .suspect-button {
    cursor: pointer;
    outline: none;
    padding: .75em 1em;
    border-radius: .4em;
    color: #fff;
    background-color:#ffb861 ;
    transition: all 0.15s ease;
    border: 0px solid #ffff;
  }

 .good-button {
    cursor: pointer;
    outline: none;
    padding: .75em 1em;
    border-radius: .4em;
    color: #fff;
    background-color: #4fc08d ;
    transition: all 0.15s ease;
    border: 0px solid #ffff;
  }

  .dd{
    width:140px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding: .65em .70em;
    font-size: 14px;
    margin: 2px;
    }

  .list-note {
    font-size: 18px;
  }
</style>