<template>
    <div>
      <b-list-group-item class="d-flex align-items-center" @click="removePlayers(player.id)">
        <b-avatar :src="require(`../../assets/crew/${encodeURIComponent(player.color)}.png`)" alt="icon"  icon="people-fill" class="mr-3"></b-avatar>
        <span class="mr-auto"><h5>{{player.name}}</h5></span>
        <b-icon icon="x" variant=""></b-icon>
      </b-list-group-item>
      </div>

</template>

<script>
// import { mapGetters } from 'vuex'
// import { mapState  } from 'vuex'

  export default {
    name: 'Player',
    props: ['player'],
    methods: {
      removePlayers(id) {
          console.log(id)
           this.$store.dispatch("REMOVE_PLAYER", id);
       }
    }
    }
</script>

<style scoped>
  .title {
    color: #888;
    font-size: 18px;
    font-weight: initial;
    letter-spacing: .25px;
    margin-top: 10px;
  }

</style>