<template>
<div id="wrapper">

    <br><br>
    <b-row>
    <h2> Game Notes </h2>
    <b-col class="text-right">
    <button id='button' @click="$router.push('gamepage')"><h5>New Game</h5>  </button>
    </b-col>
    </b-row>
    <br>
    <ListOfNotes></ListOfNotes>
    <br><br>
    <br><br><br><br>
</div>
</template>

<script>

    import ListOfNotes from './NotesPage/ListOfNotes'

  export default {
    components: { ListOfNotes },
    name: 'notes-page',
    methods: {
    }
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body { font-family: 'Source Sans Pro', sans-serif; }

  #wrapper {
    background:
      radial-gradient(
        ellipse at top left,
        rgba(255, 255, 255, 1) 40%,
        rgba(229, 229, 229, .9) 100%
      );
    height: 100vh;
    padding: 60px 80px;
    width: 100vw;
  }

  #logo {
    height: auto;
    margin-bottom: 20px;
    width: 420px;
  }

  main {
    display: flex;
    justify-content: space-between;
  }

  main > div { flex-basis: 50%; }

  .left-side {
    display: flex;
    flex-direction: column;
  }

  .welcome {
    color: #555;
    font-size: 23px;
    margin-bottom: 10px;
  }

  .title {
    color: #2c3e50;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .title.alt {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .doc p {
    color: black;
    margin-bottom: 10px;
  }

  #button {
    font-size: .8em;
    cursor: pointer;
    outline: none;
    padding: 0.75em 2em;
    border-radius: 2em;
    display: inline-block;
    color: #fff;
    background-color: #939ba2;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 1px solid #939ba2;
  }

  .doc button.alt {
    color: #939ba2;
    background-color: transparent;
  }
</style>
