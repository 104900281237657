<template>
  <div>
    <b-list-group style="max-width: 300px;">
      <div v-bind:key="player.id" v-for="player in allPlayers">
      <UserListUser
        v-bind:player="player"
      ></UserListUser>
      </div>
    </b-list-group>
  </div>
</template>

<script>
  import UserListUser from './UserListUser'
  import { mapGetters } from 'vuex'

  export default {
    components: { UserListUser },

    name: "Players",
    computed: mapGetters(['allPlayers'])
  }
</script>

<style scoped>
  .title {
    color: #888;
    font-size: 18px;
    font-weight: initial;
    letter-spacing: .25px;
    margin-top: 10px;
  }

</style>