import {v4 as uuid} from 'uuid';

const state = {
    players: []

}

const getters = {
    allPlayers: (state) => state.players,

    getAlive: (state) => state.players.find(player => player.status === "Alive"),

    getPlayersNames: state => {
      return state.players.map(player => player.name)
    },

    getPlayersColors: state => {
      return state.players.map(player => player.color)
    },

    getPlayerbyid: (state) => (id) => {
        return state.players.find(player => player.id === id)
      },
}

const actions = {
    ADD_PLAYER ({ commit }, [name, color]) {
        commit('ADD_PLAYER', [name, color]);
      },
    CLEAR_PLAYERS ({ commit }) {
        commit('CLEAR_PLAYERS');
        console.log("Players Cleared")
    },
    ADD_BAD_NOTE ({ commit }, [player, note]) {
      commit('ADD_BAD_NOTE', [player, note])
    },
    ADD_SUS_NOTE ({ commit }, [player, note]) {
      commit('ADD_SUS_NOTE', [player, note])
    },
    ADD_NET_NOTE ({ commit }, [player, note]) {
      commit('ADD_NET_NOTE', [player, note])
    },
    ADD_GOOD_NOTE ({ commit }, [player, note]) {
      commit('ADD_GOOD_NOTE', [player, note])
    },
    ACCUSE ({ commit }, [accuser_id, accused_id]) {
      commit('ACCUSE', [accuser_id, accused_id])
    },
      REMOVE_PLAYER: (context, payload) => {
        context.commit("REMOVE_PLAYER", payload)
    },
    NEW_GAME: (context) => {
      context.commit("NEW_GAME")
  },
  PLAYER_DEAD({ commit }, [dead_id, found_body_id]) {
    commit('PLAYER_DEAD', [dead_id, found_body_id])
  },
  VOTED_OFF({ commit }, [voted_off_id, voted_off_colors]) {
    commit('VOTED_OFF', [voted_off_id, voted_off_colors])
  },
  REVERT_DEAD_VOTED({ commit }, player_id) {
    commit('REVERT_DEAD_VOTED', player_id)
  }
}

const mutations = {
    ADD_PLAYER (state, [name, color]) {
        var icon = color
        state.players = [ ...state.players, { 
          id: uuid(), 
          name, 
          color,
          icon,
          alive: true,
          foundTheirBody: false,
          votedOffBy: [],
          noteBad: [],
          noteSus: [],
          noteNeutral: [], 
          noteGood: [],
          accusedBy: [],
          accusedWho: [],
        } ];
      },
    
    ADD_BAD_NOTE (state,  [player , note]) {
      var index = state.players.findIndex(players => players.id === player.id);
      state.players[index].noteBad.push(note)
    },
    ADD_SUS_NOTE (state,  [player , note]) {
      var index = state.players.findIndex(players => players.id === player.id);
      state.players[index].noteSus.push(note)
    },
    ADD_NET_NOTE (state,  [player , note]) {
      var index = state.players.findIndex(players => players.id === player.id);
      state.players[index].noteNeutral.push(note)
    },
    ADD_GOOD_NOTE (state,  [player , note]) {
      var index = state.players.findIndex(players => players.id === player.id);
      state.players[index].noteGood.push(note)
    },
    // REVERT_ACCUSED (state,  [accuser_id, accused_id]) {
      
    // },
    ACCUSE (state,  [accuser_id, accused_id]) {
      var accuser_index = state.players.findIndex(players => players.id === accuser_id);
      var accused_index = state.players.findIndex(players => players.id === accused_id);
      var accuser_color = state.players.find(player => player.id === accuser_id)
      var accused_color = state.players.find(player => player.id === accused_id)
      state.players[accuser_index].accusedWho.push(accused_color.color)
      state.players[accused_index].accusedBy.push(accuser_color.color)
    },
    CLEAR_PLAYERS (state) {
        state.players = [];
    },
    REMOVE_PLAYER (state, payload) {
      var index = state.players.findIndex(players => players.id === payload);
      state.players.splice(index, 1);
    },
    PLAYER_DEAD (state,  [dead_id, found_body_id]) {
      var found_color = 'noOne';
      if (found_body_id === 'noOne') {
        found_color = 'noOne';
      } else {
        found_color = state.players.find(player => player.id === found_body_id)
        found_color = found_color.color
      }
      var dead_index = state.players.findIndex(players => players.id === dead_id);

      state.players[dead_index].alive = false
      state.players[dead_index].icon = ("dead_" + state.players[dead_index].color)
      state.players[dead_index].foundTheirBody = found_color
    },
    VOTED_OFF (state,  [voted_off_id, voted_off_colors]) {
      var voted_color = state.players.findIndex(player => player.id === voted_off_id);
      state.players[voted_color].alive = false;
      state.players[voted_color].icon = ("dead_" + state.players[voted_color].color);
      state.players[voted_color].votedOffBy = voted_off_colors;
      console.log(state.players[voted_color].votedOffBy)
    },
    NEW_GAME (state) {
      state.players.forEach((player) => {
          player.alive = true,
          player.icon = player.color,
          player.foundTheirBody = false,
          player.votedOffBy = [],
          player.noteBad = [],
          player.noteSus = [],
          player.noteNeutral = [], 
          player.noteGood = [],
          player.accusedBy = [],
          player.accusedWho = []

      });
    },
    REVERT_DEAD_VOTED (state,  player_id) {
        var player_index = state.players.findIndex(player => player.id === player_id);
        state.players[player_index].alive = true
        state.players[player_index].votedOffBy = []
        state.players[player_index].foundTheirBody = false
        state.players[player_index].icon = state.players[player_index].color
    }
}
  export default {
    state,
    getters,
    mutations,
    actions
  }