<template> 
<button  class='action-button' v-b-modal="'modal-prevent-vot' + player.id">
  Voted Off <b-icon icon="hammer"/>
  <div>
    <b-modal v-bind:id="'modal-prevent-vot' + player.id"  ref="modal" hide-footer v-bind:title="player.name + ' Voted Off!'">
      <div class="d-block text-center">
       </div>     
            <div>
                <b-form-group v-bind:label="'Select Crew Mates that voted ' +  player.name + ' off'">
                <b-form-tags v-model="value" size="lg" add-on-change no-outer-focus class="mb-2">
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                            @remove="removeTag(tag)"
                            :title="tag"
                            variant="info"
                        >{{ tag }}</b-form-tag>
                        </li>
                    </ul>
                        <b-form-select
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || getPlayersColors.length === 0"
                            :options="getPlayersColors"
                        >
                            <template v-slot:first>
                            <option disabled value="">Pick Voter Colors..</option>
                            </template>
                        </b-form-select>
                    </template>
                </b-form-tags>
                </b-form-group>
                <button id='button' @click="addVotes()">Submit</button>
            </div>
            </b-modal>
        </div>
    </button>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
    props: {
      player: {},
    },
    computed: mapGetters(['allPlayers', 'getPlayersColors']),    
    data() {
      return {
         value: []
      }
    },
      methods: {
      addVotes() {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-vot' + this.player.id)
          
          this.$store.dispatch("VOTED_OFF", [ this.player.id, this.value]);
        })
      }
    },
}
</script>

<style>

  #button {
    font-size: .8em;
    cursor: pointer;
    outline: none;
    padding: 0.75em 2em;
    border-radius: 2em;
    display: inline-block;
    color: #fff;
    background-color: #939ba2;
    transition: all 0.15s ease;
    box-sizing: border-box;
    border: 1px solid #939ba2;
  }
</style>