<template>
    <button class='suspect-button dd' v-b-modal="'modal-prevent-' + player.id + '-' + color" v-bind:variant="color">


    <div>
        {{text}} Note <b-icon icon="search">  </b-icon>
        <b-modal
        v-bind:id="'modal-prevent-' + player.id + '-' + color"
            ref="modal"
            v-bind:title="'Add Your ' + text + ' for ' + player.name"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
          <button type="button" class="btn btn-outline-warning btn-sm bb" @click="note = 'Faking a task' ; handleQuick();"> Faking a task </button>
          <button type="button" class="btn btn-outline-warning btn-sm bb" @click="note = 'Walking Weird/Chasing' ; handleQuick();"> Walking Weird/Chasing </button>
          <button type="button" class="btn btn-outline-warning btn-sm bb" @click="note = 'Did not Report Body' ; handleQuick();"> Didn't Report Body </button>
            <br>

            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                :state="notestate"
                label-for="note-input"
                invalid-feedback="Note is required"
                >
                <b-form-input
                    id="note-input"
                    v-model="note"
                    :state="notestate"
                    placeholder="Custom Note:"
                    required
                ></b-form-input>
                </b-form-group>
            </form>
        
        </b-modal>
        </div>
    </button>
</template>

<script>

import { mapState  } from 'vuex'


export default {
    data() {
      return {
        note: '',
        notestate: null,
      }
    },

    props: {
        player: {
            
        },
        text: {
            type: String,
        },
        color: {
        type: String,
      },
     },
    methods: {               
        checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.notestate = valid
        return valid
      },
      resetModal() {
        this.note = ''
        this.notestate = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        if (!this.checkFormValidity()) {
          return
        }
        this.$store.dispatch("ADD_SUS_NOTE", [ this.player, this.note]);
        this.note = ''
        this.notestate = null
        this.$nextTick(() => {
          this.$bvModal.hide("modal-prevent-" + this.player.id + '-' + this.color)
        })
      },


      handleQuick() {
        this.$store.dispatch("ADD_SUS_NOTE", [ this.player, this.note]);
        this.note = ''
        this.notestate = null
        this.$nextTick(() => {
          this.$bvModal.hide("modal-prevent-" + this.player.id + '-' + this.color)
        })
      }
    },
    computed: {
        ...mapState({
        players: state => state.player.players
    }),
    getClass() {
            return "modal-prevent-" + this.player.id + '-' + this.color
        },
    }


    }


</script>

<style>

.bb {
  margin: 4px;
  margin-bottom: 1.5em;

}

</style>